<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  modelValue: string|number|boolean,
  radioValue?: string|number|boolean,
  isRadio?: boolean
  label?: string,
  labelPosition?: string,
  checked?: boolean,
  type?: 'checkbox' | 'radio',
  round?: boolean,
  id?: string,
  size?: string,
  utilityClass?: string,
}>(), {
  type: 'checkbox',
  round: false,
  label: '',
  labelPosition: 'right',
  utilityClass: '',
  size: '24px'
})

const emit = defineEmits(['update:modelValue'])

const componentId = computed(() => {
  if(props.id) {
    return props.id
  }
  else {
    return `input-${Date.now().toString(36) + Math.random().toString(36).slice(2)}`
  }
})

const localModelValue = computed({
  get () {
    return props.modelValue
  },
  set (val) {
    emit('update:modelValue', val)
  }
})



const computedClasses = computed(() => ({
  'rift-checkbox': true,
  utilityClass: !!props.utilityClass,
  'label-left': props.labelPosition === 'left',
  'rift-checkbox--radio': props.isRadio,
  'rift-checkbox__round': props.round,
}));

</script>

<template>
  <div
    v-if="componentId"
    :class="computedClasses"
  >
    <label
      v-if="$slots.labelPrepend"
      :for="componentId"
      :class="`rift-checkbox__label ${utilityClass}`"
    >
      <slot name="labelPrepend">
        {{ label }}
      </slot>
    </label>
    <input
      :id="componentId"
      v-model="localModelValue"
      :type="type"
      :class="['rift-checkbox__input', { 'rift-checkbox__round': round }, utilityClass]"
      :style="[{
        width: size,
        height: size,
      }]"
      :checked="checked"
    >
    <label
      v-if="$slots.label"
      :for="componentId"
      :class="`rift-checkbox__label ${utilityClass}`"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<style lang="postcss">
.rift-checkbox {
  --borderWidth: 2px;
  --height: 12px;
  --width: 6px;
  --borderColor: var(--color-white);

  display: flex;
  gap: 12px;

  &.label-left {
    flex-flow: row-reverse;
  }

  &__input {
    background: var(--color-white);
    border: 1px solid var(--color-grey);
    border-radius: 8px;
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;

    &:checked {
      background: var(--rift-primary);
      border: 1px solid var(--rift-primary);

      &::before {
        content: "";
        display: inline-block;
        transform: rotate(45deg);
        height: var(--height);
        width: var(--width);
        border-bottom: var(--borderWidth) solid var(--borderColor);
        border-right: var(--borderWidth) solid var(--borderColor);
        margin-bottom: 2px;
      }
    }

    &:not(:disabled) {
      cursor: pointer;

      & + .rift-checkbox__label {
        cursor: pointer;
      }
    }

    &:not(:checked).rift-checkbox__round {
      background: rgb(var(--color-grey1));
      &::before {
        content: "";
        display: inline-block;
        transform: rotate(45deg);
        height: var(--height);
        width: var(--width);
        border-bottom: var(--borderWidth) solid var(--color-grey);
        border-right: var(--borderWidth) solid  var(--color-grey);
        margin-bottom: 2px;
        background-color: rgb(var(--color-grey1));
      }
    }

    &[type=radio] {
      width: 20px;
      height: 20px;
      &:not(:checked).rift-checkbox__round {
        background: var(--color-grey1);
        &::before {
          border: 0;
        }
      }

      &:checked {
        border: 1px solid rgb(var(--rift-primary));
        background: var(--color-grey1);
        &::before {
          content: "";
          border: 0;
          background: rgb(var(--rift-primary));
          transform: rotate(0);
          width: 10px;
          height: 10px;
          margin: 0;
          border-radius: 100%;
        }

        & + .rift-checkbox__label {
          color: rgb(var(--rift-primary));
        }
      }
    }
  }

  &__round {
    border-radius: 100%;
  }
}

</style>
